<template>
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.32697 5.66668C3.32697 5.03705 3.70638 3.77779 5.22399 3.77779H11.5474C13.065 3.77779 13.4444 5.03705 13.4444 5.66668V14.4815C13.4444 15.1111 13.065 16.3704 11.5474 16.3704H5.22399C3.70638 16.3704 3.32697 15.1111 3.32697 14.4815V5.66668Z"
      fill="white"
    />
    <path
      d="M9.3342 1.8889C9.54498 1.8889 9.96654 1.76297 9.96654 1.25927C9.96654 0.755565 9.54498 0.629639 9.3342 0.629639H4.27548C3.0108 0.629639 0.481445 1.38519 0.481445 4.40742V12.5926C0.481445 12.8025 0.607913 13.2222 1.11378 13.2222C1.61966 13.2222 1.74612 12.8025 1.74612 12.5926V4.40742C1.74612 3.56791 2.252 1.8889 4.27548 1.8889H9.3342Z"
      fill="white"
    />
  </svg>
</template>